:root {
  /* [BG-BLACK] */
  /* --bg: #101010; */
  --bg: #000000;
  --bg-gradient-1: #050505;
  --bg-gradient-2: #0a0a0a;

  /* --bg-gradient-alt-1: #050505;
  --bg-gradient-alt-2: #101010; */
  
  --bg-gradient-3: #050505;
  --bg-gradient-4: #030303;

  /* [BG-BLUE] */
  /* --bg-gradient-3: #1e40af;
  --bg-gradient-4: #2563eb; */

  /* [BG-RED] */
  --bg-gradient-5: #aaaaaa;
  --bg-gradient-6: #ffffff;

  /* [BR-BLACK-GREY] */
  --br-gradient-1: #202020;
  --br-gradient-2: #404040;
  --br-gradient-3: #707070;
  --br-gradient-4: #aaaaaaaa;

  --unit-100vh: 100vh;
}

/* @supports (height: 100dvh) {
  :root {
      --unit-100vh: 100dvh;
  }
} */

body {
  background-color: var(--bg);
}

#swiper-replace .swiper-button-next {
  right: -16;
  transform: scale(1);
  width: 40px;
  height: 40px;
  border-radius: 0.125rem;
  border-width: 1px;
  border-color: #030303;
  background-size: 100% auto;
  background-position: center;
  background: #0a0a0a;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 5%);
}

/* 
h-10 interactive-element text-ui tracking-normal py-2 px-4 rounded-sm  inline-flex items-center hover:text-white hover:bg-[#101010] active:scale-95 active:outline-none focus:outline-none
*/

#swiper-replace .swiper-button-next:hover {
  background: #101010;
  outline: none;
}

#swiper-replace .swiper-button-next:active {
  transform: scale(.95);
  outline: none;
  box-shadow: inset 0 0 10px 1px rgb(0, 0, 0, 0.15);
}

#swiper-replace .swiper-button-next::after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(./images/chevron-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  /* filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 20%)); */
}

svg:hover {
  fill: #ffffff;
}

#swiper-replace .swiper-button-prev {
  left: -16;
  transform: scale(1);
  width: 40px;
  height: 40px;
  border-radius: 0.125rem;
  border-width: 1px;
  border-color: #030303;
  background-size: 100% auto;
  background-position: center;
  background: #0a0a0a;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 5%);
}

#swiper-replace .swiper-button-prev:hover {
  background: #101010;
  outline: none;
}

#swiper-replace .swiper-button-prev:active {
  transform: scale(.95);
  outline: none;
  box-shadow: inset 0 0 10px 1px rgb(0, 0, 0, 0.15);
}


#swiper-replace .swiper-button-prev::after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(./images/chevron-left.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 20%));
}

#swiper-replace .swiper-pagination-bullet {
  width: 25px;
  height: 5px;
  border-radius: 4px;
  opacity: 0.1;
  background: #ffffff;
}

#swiper-replace .swiper-pagination-bullet-active {
  opacity: 1;
  background-image: linear-gradient(to top, var(--bg-gradient-5), var(--bg-gradient-6));
}

/* [TILES] border-gradient-t-neutral-300-neutral-100-black-neutral-900 */
.gradient-border-background-neutral {
  background:
    linear-gradient(to top, var(--bg-gradient-1), var(--bg-gradient-1), var(--bg-gradient-2)), linear-gradient(to top, var(--br-gradient-1), var(--br-gradient-2), var(--br-gradient-2), var(--br-gradient-3), var(--br-gradient-4));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.gradient-border-background-neutral-alt {
  background:
    linear-gradient(to top, var(--bg-gradient-1), var(--bg-gradient-1), var(--bg-gradient-2));
}

.gradient-border-background-neutral-input {
  background:
    linear-gradient(to top, var(--bg-gradient-2), var(--bg-gradient-2), var(--bg-gradient-2)), linear-gradient(to top, var(--br-gradient-1), var(--br-gradient-2), var(--br-gradient-2), var(--br-gradient-3), var(--br-gradient-4));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.card::after {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: "";
  border-radius: inherit;
  box-shadow: inset 0 2px 2px 1px rgb(255 255 255 / 10%);
}

.pattern-bg-top {
  box-sizing: border-box;
  border-style: none;
  pointer-events: none;
  z-index: -1 !important;
  top: 0;
  left: 0;
  position: absolute !important;
}

.pattern-bg {
  box-sizing: border-box;
  border-style: none;
  pointer-events: none;
  z-index: -1 !important;
  left: 0;
  position: absolute !important;
}

.line-1{
  /* position: relative; */
  /* width: 12.5em; */
  /* margin: 0 auto; */
  border-right: 2px solid #aaaaaaaa;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;  
}

/* Animation */
.anim-typewriter{
animation: typewriter 3s steps(23) 1s 1 normal both, 
blinkTextCursor 500ms steps(23) infinite normal;
}
@media screen and (min-width: 768px) {
  @keyframes typewriter{
    from{width: 0;}
    to{width: 17em;}
    }
}
@media screen and (max-width: 767px) {
  @keyframes typewriter{
    from{width: 0;}
    to{width: 18em;}
    }
}

@keyframes blinkTextCursor{
from{border-right-color: #aaaaaaaa;}
to{border-right-color: transparent;}
}

/* [DIVIDER] border-gradient-t-neutral-300-neutral-100-neutral-300-black-neutral-900 */
.gradient-border-background-neutral-2 {
  background:
    linear-gradient(to top, var(--bg-gradient-1), var(--bg-gradient-2), var(--bg-gradient-1)), linear-gradient(to top, var(--br-gradient-1), var(--br-gradient-4), var(--br-gradient-1));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

/* [BLUE BUTTON] */
/* .bg-button-colored {
  background:
    linear-gradient(to top, var(--bg-gradient-3), var(--bg-gradient-3), var(--bg-gradient-4)), linear-gradient(to top, var(--br-gradient-1), var(--br-gradient-2), var(--br-gradient-2), var(--br-gradient-3), var(--br-gradient-4));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.bg-button-colored:active {
  background:
    linear-gradient(to top, var(--bg-gradient-3), var(--bg-gradient-3), var(--bg-gradient-4)), linear-gradient(to top, var(--br-gradient-1), var(--br-gradient-1), var(--br-gradient-2), var(--br-gradient-3));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
} */

/* [BLACK BUTTON] */
.bg-button-colored {
  background:
    linear-gradient(to top, var(--bg-gradient-3), var(--bg-gradient-3), var(--bg-gradient-4)), linear-gradient(to top, var(--br-gradient-1), var(--br-gradient-2), var(--br-gradient-2), var(--br-gradient-3), var(--br-gradient-4));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.bg-button-colored:active {
  background:
    linear-gradient(to top, var(--bg-gradient-3), var(--bg-gradient-3), var(--bg-gradient-4)), linear-gradient(to top, var(--br-gradient-1), var(--br-gradient-1), var(--br-gradient-2), var(--br-gradient-3));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.border-width-1 {
  border: 1px solid transparent;
}

.border-width-2 {
  border: 2px solid transparent;
}

h1 {
  line-height: normal;
}

/* .wrapper-wrapper {
  max-width: 56rem;
} */

.wrapper {
  position: relative;
  padding-top: 56.25%
  /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
}

.vh-100-mobile {
  /* height: 100vh;
  height: -webkit-fill-available; */
  height: calc(var(--vh, 1vh) * 100);
}