@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --dv-gradient-1: #20202000;
  --dv-gradient-2: #aaaaaaaa; */
  /* --dv-gradient-1: #000000;
  --dv-gradient-2: #000000;
  --dv-gradient-2: #2020207e; */
  --dv-gradient-1: #aaaaaaaa;
  --dv-gradient-2: #ffffff0D;
  @apply tracking-normal;
}

.text-gradient {
  /* -webkit-text-stroke: 0.3px black; */
  @apply font-sans font-medium text-transparent bg-gradient-to-t from-neutral-200 to-neutral-100 bg-clip-text;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 1));
}

.text-gradient-sans {
  /* -webkit-text-stroke: 0.3px black; */
  @apply font-sans font-normal text-neutral-200 text-shadow-custom;
  /* filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 1)); */
}

.divider-gradient {
  @apply bg-gradient-to-r from-[var(--dv-gradient-2)] via-[var(--dv-gradient-2)] to-[var(--dv-gradient-2)];
}

.divider-gradient-to-r {
  @apply bg-gradient-to-r from-[#aaaaaa80] to-[#20202000];
}

.text-ui {
  @apply font-light text-neutral-400 text-shadow-custom;
}

.text-button-colored {
  @apply text-neutral-200 text-shadow-custom;
}

.icon-ui {
  @apply hover:text-neutral-300;
  filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 20%));
}

.text-body {
  @apply font-light text-neutral-300 text-shadow-custom;
}

.text-link {
  @apply font-light text-neutral-100 text-shadow-custom;
}

.text-wrapper {
  @apply mx-auto md:max-w-2xl;
}

.content-wrapper {
  @apply mx-auto md:max-w-5xl px-4;
}

.padding-header {
  @apply px-4 pt-[6.4rem];
}

.padding-list {
  @apply py-6 px-4;
}

.interactive-element {
  @apply bg-[#0a0a0a] border-1 border-[#030303] shadow-reflexSmall;
}